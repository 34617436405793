<template>
  <section id="multiselect-priority">
    <multiselect
      ref="multiselect"
      track-by="value"
      :value="value"
      :options="options"
      :close-on-select="false"
      :multiple="true"
      :disabled="disabled"
      :show-labels="false"
      :placeholder="placeholder ? placeholder : 'Selecionar'"
      :searchable="false"
      openDirection="bottom"
      class="with-border multiple"
      @change="setPriorityLevel"
      @input="setPriorityLevel"
    >
    <template slot="placeholder">
        <div class="priority__placeholder">
          Prioridade
          </div>
      </template>
      <template slot="caret">
        <div @mousedown.prevent.stop="toggle" class="chevron">
          <ChevronDown />
        </div>
      </template>

      <template slot="option" slot-scope="props">
        <div class="priority__options">
          {{ props.option.label }}
        </div>
      </template>

      <template slot="selection" slot-scope="{ values, isOpen }"
        ><span class="multiselect__single" v-show="!isOpen" v-if="values?.length"
          >{{ values.length }} Selecionados</span
        ></template
      >
    </multiselect>
  </section>
</template>
<script>
export default {
  name: 'MultiSelectPriority',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: Array,
    label: String,
    disabled: Boolean,
    placeholder: String
  },
  data() {
    return {
      isOpen: false,
      options: [
        { label: 'Não urgente', value: 'LOW' },
        { label: 'Pouco urgente', value: 'MEDIUM' },
        { label: 'Urgência', value: 'HIGH' },
        { label: 'Emergência', value: 'VERY_HIGH' }
      ]
    }
  },
  methods: {
    setPriorityLevel(priorityLevel) {
      this.$emit('input', priorityLevel,)
      this.$emit('change', priorityLevel,)
    },
    toggle(event) {
      event.preventDefault()

      if (this.$refs.multiselect.isOpen) {
        this.$refs.multiselect.isOpen = false
      } else {
        this.$refs.multiselect.isOpen = true
      }
    }
  }
}
</script>
<style lang="scss">
#multiselect-priority {
  .multiselect__placeholder {
    padding-left: 0 !important;
  }
  .multiselect__single {
    padding-left: 0 !important;
  }
}
</style>
